<template>
  <div class="uk-text-danger">
    <div v-if="err">{{errorMessage}}</div>
    <slot></slot>
  </div>
</template>


<script>

export default {
  name: 'error',
  props: {err: Error},
  data: function () {
    return {
      errorMessages: {
        'Wrong code': 'Der Einladungscode ist nicht korrekt.',
        'Wrong user': 'Sie sind für diese Prüfung nicht zugelassen. Der Einlaungslin oder Einladungscode ist nicht korrekt.',
        'Too early': 'Der Prüfungszeitraum hat noch nicht begonnen.',
        'Too late': 'Der Prüfungszeitraum ist vorbei.',
        'Already submitted': 'Sie haben die Prüfung schon abgegeben.',
        'Out of time': 'Die Prüfungszeit ist abgelaufen.',
      }
    }
  },
  computed: {
    errorMessage() {
      if (this.err && this.err.response && this.err.response.data.message in this.errorMessages) {
        return this.errorMessages[this.err.response.data.message];
      }
      return 'Fehler';
    }
  }
}
</script>