<template>
  
    <div class="exam_timer">{{displayMinutesLeft}} min</div>
  
</template>

<script>

import { utcToZonedTime } from 'date-fns-tz';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';

export default {
  name: 'timer',
  data: function () {
    return {
      minutesLeft: null
    }
  },
  computed: {
    startTime () {
      return this.$store.state.attendance.start_time;
    },
    endTime () {
      return this.$store.state.attendance.end_time;
    },
    extraTimeAllowance () {
      return this.$store.state.template.extra_time;
    },
    currentTime() {
      const date = new Date()
      const timeZone = 'Europe/Berlin'
      return utcToZonedTime(date, timeZone)
    },
    displayMinutesLeft() {
      return (this.minutesLeft > 0 ? this.minutesLeft: 0);
    }
  },
  watch: {
    minutesLeft: {
      handler(value) {
        if (value !== null && value > -this.extraTimeAllowance) {
          setTimeout(() => {
            this.minutesLeft--;
          }, 60000);
        }
        this.$emit('minutes-left', value)
      },
      immediate: true
    }
  },
  mounted: function () {
    this.initialise();
  },
  methods: {
    initialise() {
      const currentTime = utcToZonedTime(new Date(), 'Europe/Berlin');
      this.minutesLeft = differenceInMinutes(parseISO(this.endTime), currentTime)+1;
    }
  }
}
</script>




