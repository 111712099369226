<template>
  <div>
    <timer v-on:minutes-left="onMinutesLeft" />
    <div class="exam-auth_attandance">Onlineprüfung: 
        <div>{{attendance.first_name}}  {{attendance.last_name}}</div>
      </div>
    <div class="exam-auth_attandance--head">Onlineprüfung zur Fachkraft
          für Rauchwarnmelder nach
          DIN 14676</div>
    <div class="exam-auth_attandance--desc">Sie haben {{textCountQuestionsViewed}} Fragen gesehen und {{textCountQuestionsAnswered}} Fragen beantwortet,
      möchten Sie die Prüfung jetzt
      abgeben?</div>

    <div v-if="lessThanFiveMinutesLeft" class="error">Sie haben noch {{minutesLeft}} min. Zeit,
      um Ihre Prüfung abzuschließen.</div>

    <div class="exam_submit">
      <button class="exam_submit--btn"><router-link :to="{ name: 'exam'}">Ich möchte meine Antworten
        noch einmal kontrollieren.</router-link></button>
    </div>
     <div class="exam_submit">
      <button @click="submit" class="exam_submit--btn">Prüfung abgeben</button>
    </div>

    <error v-if="serverError" class="error" :err="serverError" />
  </div>
</template>

<script>

import Error from './Error.vue';
import Timer from './Timer.vue';

export default {
  components: { Error, Timer },
  data: function () {
    return {
      serverError: null,
      minutesLeft: null
    }
  },
  computed: {
    attendance () {
      return this.$store.state.attendance;
    },
    submitted () {
      return !!this.$store.state.attendance.submitted;
    },
    template () {
      return this.$store.state.template;
    },
    countQuestions() {
      return this.template.questions.length;
    },
    countViewedQuestions() {
      return this.$store.state.questionsViewed.length;
    },
    allQuestionsViewed() {
      return this.countViewedQuestions === this.countQuestions;
    },
    textCountQuestionsViewed() {
      return this.allQuestionsViewed ? 'alle': this.countViewedQuestions+' von '+this.countQuestions;
    },
    answeredQuestions() {
      return this.$store.state.questionsAnswered;
    },
    countAnsweredQuestions() {
      return this.$store.state.questionsAnswered.length;
    },
    countUnansweredQuestions() {
      return this.countQuestions -  this.countAnsweredQuestions;
    },
    allQuestionsAnswered() {
      return this.countAnsweredQuestions === this.countQuestions;
    },
    textCountQuestionsAnswered() {
      return this.allQuestionsAnswered ? 'alle': this.countAnsweredQuestions+' von '+this.countQuestions;
    },
    lessThanFiveMinutesLeft() {
      return (this.minutesLeft === parseInt(this.minutesLeft, 10))
          && this.minutesLeft > 0 && this.minutesLeft <=5;
    },
    extraTimeAllowance () {
      return this.$store.state.template.extra_time;
    }
  },
  methods: {
    onMinutesLeft (value) {
      if(value === -this.extraTimeAllowance) {
        this.submit();
      }
      else {
        this.minutesLeft = value;
      }
    },

    submit() {
      this.serverError = null;

      this.axios.post('/attendances/'+this.attendance.id+'/submit')
          .then(response => {
            this.$store.commit('setAttendance', response.data.data);
            this.$router.push({ name: 'result' });
          })
          .catch(err => {
            if (err.response) {
              this.serverError = err;
            }});
    }

  }
}
</script>




