import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

const initialState = {
  authenticated: false,
  invite_code: null,
  attendance: {
    agreed_to_terms: 0
  },
  template: {
    loaded: false,
    questions: []
  },
  startTime: null,
  currentQuestionNumber: 1,
  questionsViewed: [],
  questionsAnswered: [],
};

export default new Vuex.Store({
  plugins: [createPersistedState({
    key:'qfk-pruefung',
    storage: window.sessionStorage })],
  state: initialState,
  mutations: {
    initializeState(state) {
      Object.assign(state, initialState);
    },
    setAuthenticated (state, val) {
      state.authenticated = val;
    },
    setAttendance (state, obj) {
      state.attendance = obj;
    },
    setAttendanceAgreedToTerms (state, val) {
      state.attendance.agreed_to_terms = val;
    },
    setAttendanceSubscribedToNews (state, val) {
      state.attendance.subscribed_to_news = val;
    },
    setTemplate (state, obj) {
      state.template = obj;
      state.template.loaded = true;
    },
    setCurrentQuestionNumber (state, val) {
      state.currentQuestionNumber = val;
    },
    initializeHasAnswers(state) {
      state.questionsAnswered = [];
    },
    addHasAnswer(state, id) {
      if(state.questionsAnswered.indexOf(id) === -1) {
        state.questionsAnswered.push(id);
      }
    },
    addWasViewed(state, id) {
      if(state.questionsViewed.indexOf(id) === -1) {
        state.questionsViewed.push(id);
      }
    },
    removeHasAnswer(state, id) {
      let questions = state.questionsAnswered;
      state.questionsAnswered = questions.filter( questionId => {
        if(questionId !== id) return questionId;
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
