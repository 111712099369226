import Vue from 'vue'

import './assets/styles/css/app.css'
import App from './App.vue'
import store from './store'
import router from './router'
import header from './components/StdHeader'
import footer from './components/StdFooter'


Vue.config.productionTip = false

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('authtoken');

      if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`;
      }

      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
);

import format from 'date-fns/format';

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return format(new Date(value), 'dd.MM.yyyy HH:mm')
    }
});


import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

Vue.component('stdHeader', header)
Vue.component('stdFooter', footer)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
