import Vue from 'vue';
import VueRouter from 'vue-router';
import Authenticate from '../views/Authenticate.vue';
import Exam from '../views/Exam.vue';
import ExamQuestion from '../views/ExamQuestion.vue';
import Submit from '../views/Submit.vue';
import Result from '../views/Result.vue';

import store from '../store';
import isPast from 'date-fns/isPast';
import addMinutes from 'date-fns/addMinutes';
import parseISO from "date-fns/parseISO";

Vue.use(VueRouter);

// trying to access closed routes
const ifAuthenticated = (to, from, next) => {
  if (store.state.authenticated && store.state.attendance.agreed_to_terms) {
    next();
    return;
  }
  // not fully logged in so clean up and reroute authenticate
  next('/auth');
}

// trying to access auth routes
const ifNotAuthenticated = (to, from, next) => {
  if(store.state.authenticated) {
    const answer = window.confirm('Neu einloggen?')
    if (answer) {
      store.commit('initializeState');
      next();
    } else {
      next(false);
    }
    return;
  }
  next();
}

// trying to access exam or submit pages
const ifAuthenticatedAndTestInProgress = (to, from, next) => {
  if (!store.state.authenticated || !store.state.attendance.agreed_to_terms) {
    next('/auth');
    return;
  }
  if (store.state.attendance.submitted) {
    next('/result');
    return;
  }

  next();
}

// trying to access result page
const ifAuthenticatedAndTestSubmitted = (to, from, next) => {
  if (!store.state.authenticated || !store.state.attendance.agreed_to_terms) {
    next('/auth');
    return;
  }
  if (!store.state.attendance.submitted) {
    next('/submit');
    return;
  }

  next();
}

// logout if result extra time is past
const reinitializeIfTimedOut = (to, from, next) => {
  if (store.state.authenticated && !!store.state.attendance.start_time
        && isPast(addMinutes(parseISO(store.state.attendance.end_time), store.state.template.result_extra_time))) {
      store.commit('initializeState');
      next('/auth');
      return;
  }
  next();
}

const routes = [
  {
    path: '/',
    name: 'appRoot',
    component: Exam,
    beforeEnter: ifAuthenticated,
    redirect: { name: 'exam' }
  },
  {
    path: '/pruefung',
    name: 'exam',
    component: Exam,
    beforeEnter: ifAuthenticatedAndTestInProgress,
    redirect: '/pruefung/frage/1',
    children: [
      {
        path: 'frage/:questionNumber',
        name: 'examQuestion',
        component: ExamQuestion,
        props: true
      }
    ]
  },
  {
    path: '/auth/:inviteCode?',
    name: 'authenticate',
    component: Authenticate,
    props: true,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/submit',
    name: 'submit',
    component: Submit,
    beforeEnter: ifAuthenticatedAndTestInProgress
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    beforeEnter: ifAuthenticatedAndTestSubmitted
  },
]

const router = new VueRouter({
  routes
});

router.beforeEach(reinitializeIfTimedOut);

export default router;
